<template>
    <!-- <v-card>
            <v-card-title>
            Contacts
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
            </v-card-title>
            <div v-if="contacts === null">
                <h3>No contacts found</h3>

            </div>
            <v-data-table
                :headers="headers"
                :items="contactData"
                :search="search"
                v-else
            ></v-data-table>
            <v-pagination v-if="contacts !== null" v-model="currentPage"  class="zamu-pagination my-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>

        </v-card> -->
    <v-card class="mt-10" tile flat>
            <v-card-title >
               <h3 class="zamu-heading admin-title admin-section-title"> Contacts</h3>
                <v-spacer></v-spacer>
            
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="admin-search"
                outlined
                dense
            ></v-text-field>
            </v-card-text>
              <v-alert v-if="notification !== ''"  dense text :type="`${actionClass}`" class="mt-5" >
                  {{ notification }}
                 </v-alert>
            <div v-if="contacts === null">
                <h3>No contacts found</h3>

            </div>
            <v-data-table
                :headers="headers"
                :items="contactData"
                :search="search"

                class=" users-table"
                v-else
              >
              <template v-slot:item.title="{ item }" width="20%">
               <span class="main-item"> {{ item.name }}</span>
              </template>
             <template v-slot:item.status="{ item }">
              <span class="" :class="item.status === 1 ? 'active' : 'deactivated'"> {{ item.status === 1 ? 'active' : 'deactivated' }}</span>
              </template>
              <template v-slot:top>
                <v-toolbar flat class="text-right mt-14" width="100%">
                  <v-spacer></v-spacer>
                
                  <v-dialog v-model="dialogDelete" max-width="550px">
                    <v-card>
                      <v-card-title class="">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
            <v-pagination v-if="contacts !== null" v-model="currentPage"  class="zamu-pagination my-5" :length="pagination.lastPage" :total-visible="7" ></v-pagination>
      </v-card>
</template>

<script>
import coreMixin from '@/mixins/coreMixin';

export default {
    name: 'Contacts',
    mixins: [coreMixin],

    data() {
        return {
            contacts: null,
            search: '',
            loading: false,
            currentPage: 1,
             notification: '',
            actionClass: '',
            dialogDelete: false,



        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Email', value: 'email' },
          { text: 'Subject', value: 'subject' },
          { text: 'Message', value: 'message' },
          { text: 'Status', value: 'status' },

          { text: 'Date Created ', value: 'created_at' },
          { text: 'Date Updated', value: 'updated_at' },
          { text: 'Actions', value: 'actions', sortable: false }

        ],
        };
    },
    computed: {
        contactData () {
            return this.contacts !== null ? this.contacts.data : null;
        },
        pagination () {
            let data = null;
            if (this.contacts !== null) {
                 data = {
                    total: this.contacts.total,
                    perPage: this.contacts.perPage,
                    page: this.contacts.page,
                    lastPage: this.contacts.lastPage,
                };
            }
            return data;

        },
    },
    watch: {
        async getUpdateStatus(status) {
            if(status) {
                await this.retrieveContacts();
            }
        },
        async currentPage() {
              await this.retrieveContacts();

        },
    },
    async mounted() {
        await this.retrieveContacts();
    },
    methods: {
        async retrieveContacts() {
            this.loading = true;
            this.requested = true;

             const payload = {
              page: this.currentPage,
            };


            const endpoint = `api/contactsList`;

            const fullPayload = {
                app: this.apiUrl,
                params: payload,
                endpoint,
            };

            try {
                const response = await this.axiosGetRequest(fullPayload);
                const contacts = response.status === 200 ? response.data : null;
                this.contacts = contacts;
                this.loading = false;
                return contacts;
            } catch (error) {
                this.notification = 'Failed to fetch contacts data. Kindly try again or contact tech support';
                this.loading = false;
            }
        },
            deleteItem (item) {
        this.deletedID = item.id;
        this.dialogDelete = true
      },

     async deleteItemConfirm () {

              const payload = {
                    status: 0,
                };
                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint: `api/deactivate/${this.deletedID}`,
                };
                  try {
                    const res = await this.axiosPutRequest(fullPayload);
                    this.loading = false;
                    this.notification = res.data ? 'Contact message successful' : 'Contact message failed to deactivate';
                    this.actionClass = res.data  ? 'success' : 'error';
                    if(res.data) {
                      this.retrieveContacts()

                    }
                  

                } catch (error) {
                    this.notification = 'Contatc message failed to deactivate. Contact the admin';
                    this.actionClass = 'error';
                    this.loading = false;
                    return error.response;
                }

        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    }

}
</script>

<style>

</style>